.text {
  width: 40%;
  margin-bottom: 62px;
}

.text h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 61px;
  letter-spacing: 0.0025em;
}
.text p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.0025em;
}

.programItems {
  margin-bottom: 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1024px) {
  .programItems {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 800px) {
  .text {
    width: 100%;
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 600px) {
  .programItems {
    grid-template-columns: 1fr;
  }

  .text h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 43px;
    letter-spacing: 0.0025em;
  }
  .text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
  }
}
