.container {
  max-width: 1440px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  margin-top: 60px;
  margin-bottom: 144px;
  overflow: auto;
}

.content {
  width: 100%;
  margin: 0 45px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #223a5e;
  /* text-transform: uppercase; */
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  margin-bottom: 20px;
}
