.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-bottom: 30px;
}
.fasilitasItems {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  justify-content: space-between;
}

.fasilitasItem {
  display: flex;
  align-items: center;
}
.fasilitasItem p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #223a5e;
  text-transform: capitalize;
}
