.homeInner {
  margin-top: 100px;
  margin-bottom: 192px;
}

@media only screen and (max-width: 600px) {
  .homeInner {
    margin-top: 45px;
    margin-bottom: 100px;
  }
}
