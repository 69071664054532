.testimoni {
  transition: none;
}

.testimoniInner {
  padding-bottom: 222px;
  display: grid;
  text-align: left;
  grid-template-columns: 1fr 391px;
  gap: 72px;
  line-height: normal;
  white-space: normal;
}

.testimoniHeader {
  margin-bottom: 62px;
  max-width: 531px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: 0.0025em;
  margin-bottom: 14px;
}

.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0025em;
  margin-bottom: 32px;
}

/* side left */
.testimoniSideleft {
  max-width: 680px;
}

.testimoniTextGroup {
  margin-bottom: 14px;
}
.quotes {
  margin-bottom: 24px;
}

.testimoniText {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.0025em;
}

.testimoniName {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.0025em;
}
.testimoniJobs {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  margin-bottom: 11px;
}
/* side right */
.image {
  width: 100%;
  object-fit: contain;
}
.image img {
  width: 391px;
  height: 459px;
  object-fit: cover;
  border-radius: 8px;
}

/* Button Next And Prev */
.rightNav {
  position: absolute;
  right: 0;
  bottom: 99px;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
  padding: 20px 0;
}

.leftNav {
  position: absolute;
  right: 174px;
  bottom: 99px;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
  padding: 20px 0;
}
@media only screen and (max-width: 900px) {
  .testimoniSideright {
    width: 391px;
  }

  .testimoniInner {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 600px) {
  .image {
    min-height: 255px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 8px;
  }

  .image img {
    width: 100%;
    max-width: 391px;
    max-height: 255px;
    object-fit: cover;
    border-radius: 8px;
  }

  .testimoniInner {
    gap: 30px;
  }

  .testimoniSideright {
    width: 217px;
  }

  .testimoniHeader {
    margin-bottom: 32px;
    max-width: unset;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 43px;
    letter-spacing: 0.0025em;
  }

  .subTitle {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.0025em;
    max-width: 100%;
  }

  .quotes {
    margin-bottom: 13px;
  }

  .testimoniText {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0025em;
  }

  .testimoniName {
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.0025em;
  }

  .testimoniJobs {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.0025em;
    margin-bottom: 5px;
  }

  .leftNav {
    right: auto;
  }
}
