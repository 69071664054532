.aboutPage {
  min-height: 100vh;
  padding-top: 57px;
  background-color: #f9fbff;
}

/* .titleGroup {
  margin-bottom: 51px;
}
.titleGroup h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 61px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  margin-bottom: 19px;
} */

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.imageContainer img {
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}
.description {
  margin-bottom: 19px;
}
.description p {
  text-align: justify;
}

@media only screen and (max-width: 1024px) {
  .productGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .filterGroup {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .titleGroup h2 {
    font-size: 26px;
    margin-bottom: 0px;
  }
  .emptyCart {
    margin-left: 100px;
  }
}
