.cardProgram {
  padding: 28px 28px 50px;
  background: #ffffff;
  border-radius: 8px;
  transition: 0.2s all ease;
  cursor: pointer;
  position: relative;
}

.cardProgram:hover,
.cardProgram:active {
  filter: drop-shadow(0px 0px 20px rgba(172, 171, 171, 0.25));
}

.programDescription {
  text-align: left;
  margin-top: 18px;
}

.thumbnail {
  width: 100%;
  min-height: 207px;
  border-radius: 8px;
  object-fit: cover;
}

.thumbnail img {
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

/* start program info */
.programInfoRatingGroup,
.programInfoDuration,
.programInfotrainingTypeGroup {
  display: flex;
  align-items: center;
  gap: 8px;
}

.programInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.programInfo p {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.0025em;
}

.verticalLine {
  width: 1px;
  min-height: 16px;
  max-height: 100%;
  background-color: #000000;
  margin: 0 8px;
}

.programInfotrainingTypeGroup p {
  color: #25b34b;
  text-transform: capitalize;
}

.programInfoTimeStart p {
  text-transform: capitalize;
}
/* end program info */

.category {
  display: inline-block;
  padding: 8px 16px;
  background: #223a5e;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  margin-bottom: 23px;
}

.category p {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.titleContainer {
  margin-bottom: 7px;
  min-height: 85px;
  text-overflow: ellipsis;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.0025em;
  color: #223a5e;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #223a5e;
  margin-bottom: 10px;
}

.priceDiscountGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}
.priceDiscountGroupHidden {
  visibility: hidden;
}
.priceBeforDiscount {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.0025em;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
}
.pricePercentDiscount {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.0025em;
  color: #f43030;
}

.addToChartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 33px rgba(140, 140, 140, 0.25);
  position: absolute;
  bottom: 54px;
  right: 28px;
  z-index: 1;
}

.addToChartContainer:hover,
.addToChartContainer:active {
  background: #1bbc34;
}

.addToChartContainer a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addToChartContainer:hover svg path,
.addToChartContainer:active svg path {
  fill: #ffffff;
  stroke: #ffffff;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.modalIcon {
  padding: 5px 10px;
}

.modalTitle {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-top: 20px;
  margin-bottom: 20px;
}
