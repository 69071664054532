.misiItem {
  display: flex;
  align-items: center;
  margin: 30px 0px;
}
.misiDesc {
  display: flex;
  flex-direction: column;
  margin-left: 21px;
}
.misiDesc h3 {
  font-weight: 700;
  color: #213a5e;
}
