.titleGroup {
  margin-bottom: 82px;
  display: flex;
}

.titleContainer h2 {
  font-weight: 600;
  font-size: 34px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  margin-bottom: 4px;
}

.titleContainer p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 50px;
}

@media only screen and (max-width: 1024px) {
  .imageContainer img {
    max-width: 100%;
  }
}
