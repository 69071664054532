.program {
  display: flex;
  margin-bottom: 20px;
}

.cardProgram {
  /* width: 100%; */
  background: #ffffff;
  border-radius: 8px;
  transition: 0.2s all ease;
  /* cursor: pointer; */
  display: flex;
}

/* .cardProgram:hover,
.cardProgram:active {
  filter: drop-shadow(0px 0px 20px rgba(172, 171, 171, 0.25));
} */

.cover {
  max-height: 119px;
  max-width: 185px;
  border-radius: 8px;
  object-fit: cover;
}

.programDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  width: 100%;
}

.programDescription h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
}

.programDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #1bbc34;
}

.priceBeforDiscount {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.0025em;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
}

.category {
  display: inline-block;
  padding: 8px 16px;
  background: #223a5e;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  color: #ffff;
}

.btnDelete {
  padding: 20px;
  cursor: pointer;
}

.modalContainer {
  display: flex;
  align-items: flex-start;
}

.modalIcon {
  padding: 5px 10px;
}

.modalTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-bottom: 10px;
}

.modalText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #616161;
}

@media only screen and (max-width: 600px) {
  .cardProgram {
    flex-direction: column;
  }

  .cover {
    max-height: 250px;
    max-width: 250px;
    margin-bottom: 10px;
  }

  .programDescription {
    padding-left: 0px;
  }

  .programDescription h3 {
    font-size: 14px;
    line-height: 20px;
  }

  .programDetail {
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .price {
    font-size: 16px;
    margin-right: 10px;
  }

  .priceBeforDiscount {
    font-size: 12px;
  }

  .category {
    margin-top: 10px;
    font-size: 12px;
  }

  .btnDelete {
    padding: 10px;
  }
}
