.invoicePage {
  min-height: 100vh;
  padding-top: 57px;
  padding-bottom: 70px;
  background-color: #f9fbff;
}
.buttonCenter {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.titleGroup {
  margin-bottom: 51px;
}
.title {
  display: flex;
}
.titleGroup h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 61px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  margin-bottom: 19px;
}

.titleGroup p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.0025em;
}

@media only screen and (max-width: 600px) {
  .titleGroup h2 {
    font-size: 26px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .buttonCenter {
    display: none;
  }
}
