.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-bottom: 30px;
}
.persiapanItem {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.persiapanItem:last-child {
  margin-bottom: 0px;
}
.persiapanItem a,
.persiapanItem p {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  cursor: pointer;
}
.persiapanItem a:hover,
.persiapanItem p:hover {
  color: #1bbc34;
}
.statusIcon {
  padding-right: 50px;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.modalTitle {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-top: 20px;
  margin-bottom: 20px;
}
.modalTitle a {
  color: #223a5e;
  text-decoration: underline;
}
.modalTitle a:hover {
  color: #1bbc34;
}
