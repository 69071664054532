.productPage {
  min-height: 100vh;
  padding-top: 57px;
  padding-bottom: 70px;
  background-color: #f9fbff;
}

.titleGroup {
  margin-bottom: 51px;
}
.titleGroup h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 61px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  margin-bottom: 19px;
}

.titleGroup p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.0025em;
}

.filterGroup {
  display: flex;
  gap: 25px;
  margin-bottom: 82px;
}

.productGroup {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 43px;
}
@media only screen and (max-width: 1024px) {
  .productGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .filterGroup {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .filterGroup {
    grid-template-columns: 1fr;
    gap: 43px;
  }
  .productGroup {
    grid-template-columns: 1fr;
  }
}
