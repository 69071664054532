.cardKupon {
  padding: 0px 20px;
  padding-bottom: 30px;
  border-radius: 10px;
}

.kuponTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-bottom: 20px;
}

.kuponInput {
  border-radius: 6px;
}

.kuponButtonRight {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.kuponButton {
  background-color: #223a5e;
  justify-items: flex-end;
  border-radius: 6px;
  color: #ffff;
  justify-items: flex-end;
}
.kuponButton:hover {
  background-color: #223a5e;
  border: 1px solid #223a5e;
  border-radius: 6px;
  color: #ffff;
}
.kuponButton:focus {
  background-color: #223a5e;
  border: 1px solid #223a5e;
  border-radius: 6px;
  color: #ffff;
}

.listKupon {
  margin-bottom: 75px;
}

.kuponItem {
  display: flex;
  margin-bottom: 6px;
}

.checkOutlined {
  font-size: 24px;
  margin-right: 6px;
}

.kuponInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.kuponCode {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1bbc34;
}

.kuponDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #757575;
}

.bayarButtonCenter {
  display: flex;
  justify-content: center;
}

.bayarButton {
  padding: 16px 65px;
  background: #1bbc34;
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #ffffff;
}
.bayarButton:hover {
  background: #198d2b;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .cardKupon {
    min-width: 380px;
  }
}
