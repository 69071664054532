.divider {
  width: 1px;
  height: 16px;
  margin: 0 8px;
  background-color: #223a5e;
}

.infoProduct {
  padding: 41px 0;
}

.infoProductContainer {
  display: grid;
  grid-template-columns: 611px 1fr;
  gap: 46px;
}

.poster {
  width: 100%;
  object-fit: contain;
  height: auto;
}

.poster img {
  border-radius: 8px;
  width: 100%;
}

.infoProductTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.0025em;
  color: #223a5e;
  margin-bottom: 20px;
}

.infoProductTag {
  display: inline-flex;
  background: #223a5e;
  padding: 8px 16px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 20px;
}

.infoProductTypeGroup {
  margin-bottom: 20px;
}

.infoProductTypeGroup,
.infoProductDuration,
.infoProductRate {
  display: flex;
  align-items: center;
}

.infoProductDuration {
  gap: 8px;
}
.infoProductRate {
  gap: 15px;
}
.infoProductTypeGroup p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #223a5e;
}

.infoProductTypeGroup p:first-child {
  color: #ff0000;
}

.infoProductRate p {
  font-weight: 600;
}
.infoProductRate span {
  font-weight: 500;
}

.infoProductTimeGroup {
  margin-bottom: 36px;
}

.infoProductTime {
  display: flex;
  align-items: center;
  gap: 19px;
}

.infoProductTime:not(:last-child) {
  margin-bottom: 14px;
}

.infoProductTime p {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
}

.infoProductPriceGroup,
.infoProductPriceDiscountGroup {
  display: flex;
  align-items: center;
}
.infoProductPriceGroup {
  gap: 15px;
  margin-bottom: 21px;
}

.infoProductPrice {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 43px;
  letter-spacing: 0.0025em;
  color: #1bbc34;
}

.infoProductPriceDiscountGroup {
  gap: 9px;
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.0025em;
}

.infoProductPriceDiscountGroupHidden {
  visibility: hidden;
}

.infoProductPriceDiscountGroup p:first-child {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
}
.infoProductPriceDiscountGroup p:last-child {
  font-weight: 600;
  color: #f43030;
}
.btnContainer {
  display: flex;
  align-items: center;
}
.infoProductBtn {
  display: inline-block;
  width: 266px;
  height: 52px;
  font-weight: 600;
}
.addToChartContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-right: 50px;
}

.addToChartContainer:hover,
.addToChartContainer:active {
  background: #1bbc34;
}

.addToChartContainer a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.addToChartContainer:hover svg path,
.addToChartContainer:active svg path {
  fill: #ffffff;
  stroke: #ffffff;
}
.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.modalIcon {
  padding: 5px 10px;
}

.modalTitle {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 1024px) {
  .infoProductContainer {
    grid-template-columns: 411px 1fr;
  }
}
@media only screen and (max-width: 900px) {
  .infoProductContainer {
    grid-template-columns: 1fr;
  }

  .infoProductTitle {
    font-size: 30px;
    font-size: 30px;
    color: #223a5e;
    margin-bottom: 20px;
  }
  .infoProductTypeGroup {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .btnContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
