.container {
  max-width: 700px;
  margin: 40px auto;
  min-height: 100vh;
}

.card {
  box-shadow: 0px 8px 30px rgba(136, 136, 136, 0.055);
  padding: 20px;
}

.title{
  text-align: center;
  margin-bottom: 40px;
}
