.card {
  border-radius: 10px;
}

.price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.price p {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #223a5e;
}

.price p:last-child {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1bbc34;
}

.bankName {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.bankName p {
  font-weight: 600;
  font-size: 24px;
  line-height: 180%;
  color: #223a5e;
  margin-left: 25px;
}

.noRek p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #223a5e;
}

.noRek p:last-child {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1bbc34;
  margin-bottom: 35px;
  margin-top: 5px;
}

.iconCopy {
  padding-bottom: 3px;
  margin-left: 25px;
  cursor: pointer;
}

.qris {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.linkQris {
  margin-left: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  text-decoration-line: underline;
  color: #223a5e;
  cursor: pointer;
}

.codeQris {
  width: 100%;
}

.cardInformation {
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
}

.textInfo {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #223a5e;
}

.infoDesc {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #223a5e;
  margin-top: 25px;
  margin-bottom: 35px;
  text-align: center;
}

.textHelp {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #223a5e;
  margin-top: 35px;
  margin-bottom: 30px;
}

.whatsappButtonCenter {
  display: flex;
  justify-content: center;
}

.buttonWhatsapp {
  padding: 16px 65px;
  background: #1bbc34;
  border-radius: 6px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #ffffff;
}

.buttonWhatsapp:hover {
  background: #198d2b;
  color: #ffffff;
}

.iconWhatsapp {
  font-size: 19px;
  padding-left: 10px;
}

@media only screen and (max-width: 600px) {
  .bankName p {
    font-size: 16px;
  }
}
