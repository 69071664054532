#verify-page {
  height: 120vh;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .backBotton {
    margin-bottom: 50px;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: #223a5e;
    padding-left: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #9e9e9e;
    padding: 0px 20px;
    text-align: center;
    margin-bottom: 50px;
  }
  .ant-statistic {
    text-align: center;
    span {
      font-size: 34px;
    }
  }
  @media only screen and (max-width: 768px) {
    .text {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 600px) {
    .image {
      width: 70%;
    }
    .text {
      font-size: 16px;
    }
  }
}
