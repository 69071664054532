#profil {
  .container {
    margin: 40px auto;
    min-height: 100vh;
    overflow: auto;
    // width: 1050px;
  }

  .card {
    padding: 20px;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .ant-btn {
    margin-top: 40px;
  }

  .ant-tabs-nav {
    margin-bottom: 28px;
  }
  .ant-tabs-nav-list {
    width: 300px;
  }

  .ant-tabs-nav:before {
    border-bottom: 0px;
  }

  .ant-tabs-tab-btn {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #223a5e;
  }

  .ant-tabs-tab-btn:hover {
    color: #1bbc34;
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #223a5e;
    margin: 20px 0px;
  }
  @media only screen and (min-width: 768px) {
    .inputForTablet {
      width: 300px;
    }
  }
}
