.titleGroup {
  text-align: center;
  margin-bottom: 45px;
}
.titleGroup h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 61px;
  letter-spacing: 0.0025em;
  margin: 0;
}

.content {
  background: url('./images/background-buku.jpg') no-repeat center center;
  background-size: cover;
  min-height: 544px;
  border-radius: 18px;
  width: 100%;
  display: grid;
  place-items: center;
}

.imageContainer {
  width: 100%;
}

.btnContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .belajarMandiriContainer {
    width: 100%;
  }

  .contentInner {
    padding: 0 18px;
  }

  .imageContainer {
    width: 100%;
  }

  .imageContainer img {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .belajarMandiriContainer {
    width: 100%;
    text-align: center;
  }
  .titleGroup h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 61px;
    letter-spacing: 0.0025em;
    margin-bottom: 5px;
  }

  .titleGroup p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.0025em;
  }
  .content {
    margin-top: 52px;
    border-radius: 0;
    width: 100%;
    overflow-y: hidden;
  }

  .contentInner {
    width: 100%;
    overflow: hidden;
    padding: 0;
  }

  .imageContainer {
    width: 100%;
    overflow: auto;
  }

  .imageContainer img {
    width: 645px;
  }
}

@media only screen and (max-width: 600px) {
  .titleGroup h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.0025em;
  }
}
