.container {
  margin: 80px 0px 80px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.card {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 18px 18px;
  border-radius: 18px;
  max-width: 503px;
  width: 100%;
  margin: 0px 20px 0px 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #223a5e;
  border-radius: 18px 18px 0 0;
  padding: 20px;
}

.content {
  padding: 20px 46px;
}

.content p {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #223a5e;
  margin-bottom: 40px;
  text-align: center;
}

.forgotPassword {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  color: #9e9e9e;
}
.forgotPassword:hover {
  color: #9e9e9e;
  text-decoration: underline;
}

.content p:last-child {
  font-size: 18px;
}

.btnSubmit {
  margin-top: 40px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.modalIcon {
  padding: 5px 10px;
}

.modalTitle {
  text-align: center;
  font-style: normal;
  /* font-weight: 700; */
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-top: 20px;
  margin-bottom: 20px;
}
