.card {
  border-radius: 10px;
}

.price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.price p {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #223a5e;
}

.price p:last-child {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1bbc34;
}

.bankName {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.bankName p {
  font-weight: 600;
  font-size: 24px;
  line-height: 180%;
  color: #223a5e;
  margin-left: 25px;
}

.noRek p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #223a5e;
}

.noRek p:nth-child(2) {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #1bbc34;
  margin-bottom: 35px;
  margin-top: 5px;
}

.noRek p:last-child {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #223a5e;
}

.iconCopy {
  padding-bottom: 3px;
  margin-left: 25px;
  cursor: pointer;
}

.cardInformation {
  margin-top: 30px;
  border-radius: 10px;
}

.textInfo {
  margin-left: 24px;
}

.textInfo li {
  text-align: start;
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
  /* or 29px */

  letter-spacing: 0.0025em;
}

.bayarButtonCenter {
  display: flex;
  justify-content: center;
}

.bayarButton {
  padding: 16px 65px;
  background: #1bbc34;
  border-radius: 6px;
  border: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #ffffff;
  height: 50px;
  margin-top: 30px;
}

.bayarButton:hover {
  background: #198d2b;
  border: 0px;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .bankName p {
    font-size: 16px;
  }
  .textInfo li {
    font-size: 14px;
  }
}
