#home-page__homeBanner {
  // Handling Search Input
  .ant-input-affix-wrapper {
    padding: 14px 14px 14px 21px;
    border-radius: 6px;
    width: 100%;
    max-width: 518px;
    height: 54px;
    // Search Icon
    .ant-input-prefix {
      position: relative;
      margin-right: 20px;
      padding-right: 20px;
      &::after {
        content: '';
        position: absolute;
        height: 80%;
        border-right: 1px solid #A0A0A0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .search-icon {
        display: flex;
        align-items: center;
        .anticon .anticon-search {
          font-size: 16px;
        }
        .icon-divider {
          rect {
            fill: #a0a0a0;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  #home-page__homeBanner {
    // Handling Search Input
    .ant-input-affix-wrapper {
      max-width: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  #home-page__homeBanner {
    // Handling Search Input
    .ant-input-affix-wrapper {
      padding: 3px 10px 3px 19px;
      height: 40px;
      // Search Icon
      .ant-input-prefix {
        position: relative;
        margin-right: 20px;
        padding-right: 20px;
        &::after {
          content: '';
          position: absolute;
          height: 100%;
          border-right: 1px solid #a0a0a0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        .search-icon {
          display: flex;
          align-items: center;
          .anticon .anticon-search {
            font-size: 16px;
          }
          .icon-divider {
            rect {
              fill: #a0a0a0;
            }
          }
        }
      }
    }
    .btn{
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 32px;
    }
  }
}
