.bannerGroup {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 98px);
  background-image: url('./images/banner-background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
}

.hero {
  position: absolute;
  bottom: 0;
  right: 0;
}

.homeBannerInner {
  text-align: left;
  max-width: 818px;
}

.homeBannerInner h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 70px;
  color: #ffffff;
  margin-bottom: 15px;
}

.homeBannerInner > p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

.homeBannerInner > p > span {
  font-weight: 600;
}
/* Search Group */
.searchGroupInner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 11px;
  margin-top: 34px;
}

.searchInput {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 14px 21px;
}

.searchGroupInner .btn.btn-outlined {
  border: 1px solid #ffffff;
}

.textPalingDicari {
  margin-top: 14px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}

.textPalingDicari > span {
  font-weight: 600;
}

/* Counter */
.counterGroup {
  display: flex;
  align-items: center;
  margin-top: 71px;
}

.divider {
  width: 1px;
  height: 84px;
  margin: 0 30px;
  background-color: #ffffff;
}

.counterItem {
  min-width: 150px;
}

.counterNumber {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 120%;
  color: #ffffff;
}

.counterTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  margin-top: 4px;
}

@media only screen and (max-width: 1366px) {
  .bannerGroup {
    min-height: 819px;
  }

  .hero img {
    width: 530px;
  }
}

@media only screen and (max-width: 600px) {
  .bannerGroup {
    min-height: 400px;
  }
  .homeBannerInner h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #ffffff;
  }

  .homeBannerInner > p {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  .homeBannerInner > p > span {
    font-weight: 600;
  }

  /* Search */
  .textPalingDicari {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
  }

  /* Counter */
  .counterGroup {
    margin-top: 26px;
  }
  .counterItem {
    min-width: auto;
  }

  .divider {
    height: 40px;
    margin: 0 15px;
  }

  .counterNumber {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
  }

  .counterTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
  }
}
