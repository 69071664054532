body {
  background-color: #ffff;
}
#printInvoice {
  .ant-card-bordered {
    border: 0px;
  }
  .ant-card {
    padding: 30px;

    .ant-row {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0px;
      }
    }

    span {
      font-weight: 700;
      color: #223a5e;
    }

    .code {
      img {
        width: 70px;
        margin-left: 15px;
      }

      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 180%;
        letter-spacing: 0.0025em;
      }
    }

    .date {
      text-align: end;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 180%;
      }
    }

    .identity {
      padding-left: 80px;
    }

    .table-produk {
      margin-bottom: 10px;
      .ant-table-container {
        padding-bottom: 10px;
        border-bottom: 3px solid #f0f0f0;

        .ant-table-thead > tr > th {
          background-color: #ffff;
          text-align: center;
          font-style: normal;
          font-weight: 600;
          line-height: 180%;
          color: #223a5e;
          border-bottom: 3px solid #f0f0f0;
          border-top: 3px solid #f0f0f0;
        }

        .ant-table-tbody > tr > td {
          border-bottom: 0px;
          padding: 5px;
          text-align: center;
          &:first-child {
            text-align: left;
          }
        }

        .ant-table-cell-row-hover {
          background-color: #ffff;
        }
      }
    }

    .price {
      display: flex;
      justify-content: flex-end;
      padding-right: 12px;
      padding-bottom: 10px;
      border-bottom: 3px solid #f0f0f0;
      margin-bottom: 10px;
    }

    .footer {
      text-align: end;
      display: flex;
      flex-direction: column;
    }
  }
  @media only screen and (max-width: 600px) {
    .overflowInvoice {
      overflow: auto;
    }
    .ant-card {
      padding: 10px;
      .ant-row {
        width: 600px;
      }
    }
  }
}
