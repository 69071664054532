.container {
  text-align: center;
  margin-bottom: 40px;
}
.note{
  color: #f05959;
}
.title {
  margin-bottom: 15px;
}

.total {
  margin-top: 20px;
}

.total p {
  margin: 0;
}

.totalBayar {
  font-weight: 700;
  font-size: 34px;
  letter-spacing: 0.0025em;
  color: #1bbc34;
}
.metode{
  margin-top: 40px;
}

.metode > p{
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 25px;
}

.metodePembayaran {
  margin-bottom: 30px;
}

.metodePembayaran a {
  text-decoration: underline;
  color: #223a5e;
}

.rekening {
  font-weight: 700;
  font-size: 20px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #223a5e;
}

.logo {
  margin-bottom: 15px;
}