.message {
  height: 40px;
  background-color: #ffbb32;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message p {
  color: #213a5e;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.0025em;
}

.messageLink {
  color: #213a5e;
  text-decoration: underline;
  font-weight: bold;
}
.messageLink:hover {
  color: #1bbc34;
}

.navbar {
  background: #ffff;
  padding: 28px 0;
}

.navbarInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.humbergerIcon {
  font-size: 24px;
}

@media only screen and (max-width: 1336px) {
  .navbar {
    background: #fcfdff;
    padding: 22px 0;
  }
  .navbarMenuTogleMobile {
    display: flex;
    align-items: center;
    gap: 60px;
  }

  .shoppingChart {
    padding: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .logoContainer {
    text-align: center;
    margin-top: 11px;
    margin-bottom: 36px;
  }
}
