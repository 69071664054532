// LIST ANTD STYLING
// CUSTOM ANTD DRAWER 
// CUSTOM ANTD SELECT

// CUSTOM ANTD DRAWER 
.ant-drawer-mask {
  background: rgba(34, 58, 94, 0.5);
}

// CUSTOM ANT SELECT 
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}


.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: inherit;
}

// BUTTON
.ant-btn {
  height: 40px;
  border-radius: 6px;
  padding: 8px 16px;
  &.ant-btn-default {
    background: #ffffff;
    border: 1px solid #223a5e;
    color: #223a5e;

    &:hover {
      background: #f5f5f5;
      border: 1px solid #e0e0e0;
    }
    
    &:active {
      background: #ededed;
      border: 1px solid #ededed;
    }
  }
  &.ant-btn-primary {
    background: #223a5e;
    border: 1px solid #223a5e;
    color: #ffffff;

    &:hover {
      background: #1b3863;
      border: 1px solid #1b3863;
    }
  }
}
