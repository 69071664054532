@import './assets/styles/colors';
@import './assets/styles/button.scss';
@import './assets/styles/antd-custom.scss';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

// LIST GLOBAL STYLING
// PARAGRAF
// LOADING CONTAINER
// Media Query (1440, 1366, 1024, 900, 600)
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  background-color: #fcfdff;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
}

// PARAGRAF
a {
  color: $textColorPrimary;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
h1,
h3,
h4,
h5,
h6,
p {
  color: #000000;
}

h2 {
  color: #223a5e;
}

.bg-primary {
  background-color: $primaryColor;
}

// LOADING CONTAINER
.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media only screen and (max-width: 1440px) {
  .container {
    width: calc(100% - 290px);
  }
}

@media only screen and (max-width: 1366px) {
  .container {
    width: 90%;
  }
}
