.container {
  margin-top: 50px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffff;
  padding: 30px;
}

.listContainter {
  margin: 20px 0px 0px 40px;
}
.listKatalog p {
  width: max-content;
  border: 1px solid #213a5e;
  border-radius: 30px;
  padding: 8px 16px;
  margin-bottom: 15px;
}
.listKatalog p:hover {
  color: #ffff;
  background-color: #213a5e;
  width: max-content;
  border: 1px solid #213a5e;
  border-radius: 30px;
  padding: 8px 16px;
  margin-bottom: 15px;
}
/* .listContainter h3 {
  font-weight: 700;
  color: #213a5e;
} */

@media only screen and (max-width: 1024px) {
  .imageContainer img {
    width: 100%;
  }
}
