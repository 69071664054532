#tentang-mindo-page {
  background-color: #f9fbff;
  min-height: 100vh;

  h2 {
    margin-bottom: 51px;
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    margin-bottom: 19px;
  }
  p {
    font-weight: 400;
    font-size: 20px;
  }

  @media only screen and (max-width: 600px) {
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
  }
}
