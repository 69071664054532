.header {
  text-align: center;
}

.header > h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 61px;
  letter-spacing: 0.0025em;
}

.header > p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.0025em;
}

.faqContainer {
  margin-top: 70px;
  margin-bottom: 26px;
}

.footerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pertanyaanLanjutanGroup {
  margin-top: 77px;
  text-align: center;
}

.pertanyaanLanjutanTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #0a0a0a;
}

.pertanyaanLanjutanSubTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #0a0a0a;
}

.pertanyaanLanjutanBtnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 46px;
}

@media only screen and (max-width: 600px) {
  .header > h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 43px;
    margin-bottom: 4px;
  }

  .header > p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.0025em;
  }
  .faqContainer {
    margin-top: 20px;
    margin-bottom: 26px;
  }
}
