#verified-page {
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  width: 100%;
  justify-content: center;
  p span {
    color: #1bbc34;
  }

  p a {
    color: #1bbc34;
  }
  .image {
    margin-right: 55px;
  }
  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: #1bbc34;
    padding-left: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #9e9e9e;
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 600px) {
    .image {
      margin-right: 0px;
      width: 70%;
    }
    .title {
      font-size: 28px;
      padding-left: 0px;
    }
    .text {
      font-size: 16px;
      margin-bottom: 50px;
    }
  }
}
