.floatBtnGroup {
  position: fixed;
  bottom: 30px;
  right: 20px;
  transition: 0.2s all ease;
}
.floatBtnGroupHidden{
  transform: translateY(30px);
  transform: scale(0);
  visibility: hidden;
}
.floatContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.circle {
  background: #f5f5f5;
  border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.scrollUp {
  width: 34px;
  height: 34px;
}
.whatasapp {
  width: 60px;
  height: 60px;
}



@media only screen and (max-width: 1024px) {
  .floatBtnGroup {
  }
}

@media only screen and (max-width: 600px) {
  .floatBtnGroup {
  }
}

