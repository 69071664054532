#resetPass-page {
  p span {
    color: #1bbc34;
  }

  p a {
    color: #1bbc34;
  }

  .btn {
    padding: 11px 21px;
  }
}
