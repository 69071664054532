.navbarActionGroup {
  display: flex;
  align-items: center;
  gap: 31px;
}
.shoppingChart {
  font-size: 24px;
}
.navbarActionButton {
  display: flex;
  align-items: center;
  gap: 10px;
}
.navbarActionButton .btn {
  padding: 8px 16px;
}
.button {
  padding: 10px 15px;
  background: #ffffff;
  border: 1px solid #223a5e;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #223a5e;
  transition: 0.2s all ease;
  height: 44px;
  box-shadow: 0px 1px 2px rgb(0 0 0 / 25%);
  border-radius: 6px;
  letter-spacing: -0.006em;
}
.button:hover {
  color: #1bbc34;
  background: #f5f5f5;
  border: 1px solid #dfdfdf;
}
@media only screen and (max-width: 1024px) {
  .navbarAction {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 31px;
  }

  .navbarActionButton {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
}
