#kelas {
  .ant-card {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    .ant-card-body {
      padding: 0px;
    }
  }
  .emptyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px;
    width: 950px;
    .emptyText {
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 37px;
      color: #223a5e;
      margin-top: 50px;
      margin-bottom: 10px;
    }
    .emptySubText {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 29px;
      color: #9e9e9e;
    }
    @media only screen and (max-width: 768px) {
      .emptyImage {
        width: 50%;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .emptyContent {
      .emptyImage {
        width: 30%;
      }
      .emptyText {
        font-size: 26px;
      }
    }
  }
}
