/* Durasi Modal */
.durasiModal {
  width: 50% !important;
  border-radius: 6px;
}
.durasiTableHeader,
.durasiTableColParent {
  font-weight: 600;
}
.durasiTableRow {
  display: grid;
  grid-template-columns: 60px 1fr 15%;
}

.durasiTableCol {
  border: 1px solid #ccc;
  padding: 15px;
}

.durasiTableRow .durasiTableCol:first-child {
  text-align: center;
}

.durasiTableFooter .durasiTableCol:first-child {
  grid-column: 1/3;
  text-align: left;
}

@media only screen and (max-width: 1024px) {
  .durasiModal {
    width: 90% !important;
  }
}

@media only screen and (max-width: 600px) {
  .durasiModal {
    width: 100% !important;
  }
  .durasiTableRow {
    display: grid;
    grid-template-columns: 50px 1fr 80px;
    font-size: 12px;
  }
}
