#home-page__FAQ {
  margin-bottom: 155px;
  .ant-collapse-item {
    background: #ffffff;
    border-radius: 6px;
    border-bottom: none;
    margin-bottom: 30px;
    .ant-collapse-header {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 180%;
      letter-spacing: 0.0025em;
      color: #0a0a0a;
      width: 100%;
      margin-bottom: 0.6em;
    }
    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-top: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 180%;
        letter-spacing: 0.0025em;
        color: #0a0a0a;
      }
    }
  }
  .faq-container {
    margin-top: 70px;
    margin-bottom: 26px;
    .faq-row-wrapper {
      background-color: transparent;
      .faq-body {
        .faq-row {
          background: #ffffff;
          border-radius: 6px;
          border-bottom: none;
          margin-bottom: 14px;
          padding: 20px 79px;
          .row-title {
            position: relative;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 32px;
            letter-spacing: 0.0025em;
            color: #000000;
          }

          .row-content {
            .row-content-text {
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 32px;
              letter-spacing: 0.0025em;
              color: #000000;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  #home-page__FAQ {
    margin-bottom: 155px;
    .faq-container {
      margin-top: 20px;
      margin-bottom: 26px;
      .faq-row-wrapper {
        background-color: transparent;
        .faq-body {
          .faq-row {
            background: transparent;
            border-radius: 6px;
            border-bottom: none;
            margin-bottom: 14px;
            padding: 8px;
            .row-title {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 25px;
              letter-spacing: 0.0025em;
            }
            .row-content {
              .row-content-text {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 25px;
                letter-spacing: 0.0025em;
              }
            }
          }
        }
      }
    }
  }
}
