.titleGroup {
  margin-bottom: 41px;
  display: flex;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.cardItem {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  padding: 42px 52px;
  cursor: pointer;
  transition: 0.2s all ease;
  border: 1px solid #1b3863;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.iconContainer {
  margin-bottom: 20px;
}
.iconContainer img {
  border: 1px solid #ffffff;
  border-radius: 8px;
}
.cardItem h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: 0.0025em;
  color: #223a5e;
  margin-bottom: 10px;
  text-align: center;
}

.cardItem p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #0a0a0a;
}
.cardItem:hover {
  background-color: #213a5e;
}
.cardItem:hover h3 {
  color: #ffffff;
}
.cardItem:hover p {
  color: #ffffff;
}

@media only screen and (max-width: 1024px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .cardContainer {
    grid-template-columns: 1fr;
  }
  .titleGroup {
    margin-bottom: 16px;
  }

  .titleContainer h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.0025em;
    margin-bottom: 8px;
  }

  .titleContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0025em;
  }

  .cardItem h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.0025em;
  }

  .cardItem p {
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.0025em;
  }
}
