.inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
  /* height: 100%; */
  width: 300px;
  align-items: center;
}
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 70px;
}
.logo img {
  padding-bottom: 10px;
}
.logo h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  color: #223a5e;
}
.logo h1::first-letter {
  text-transform: capitalize;
}
