.container {
  margin: 80px 0px 80px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.card {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 18px 18px;
  border-radius: 18px;
  max-width: 503px;
  width: 100%;
  margin: 0px 20px 0px 20px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #223a5e;
  border-radius: 18px 18px 0 0;
  padding: 20px;
}

.content {
  padding: 20px 46px;
}

.content p {
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  color: #223a5e;
  margin-bottom: 40px;
  text-align: center;
}

.content p:last-child {
  font-size: 18px;
}

.btnSubmit {
  margin-top: 40px;
}
