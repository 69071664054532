#home-page__testimoni {
  // padding-top: 98px;
  margin-bottom: 102px;
}

@media only screen and (max-width: 600px) {
  #home-page__testimoni {
    margin-bottom: 80px;
  }
}
