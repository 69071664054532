.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.mobileContainer {
  margin-top: 50px;
  width: 100%;
}

.cardProgram {
  background: #ffffff;
  border-radius: 8px;
  transition: 0.2s all ease;
  /* cursor: pointer; */
  position: relative;
  padding: 24px;
}

.cardProgram:hover,
.cardProgram:active {
  filter: drop-shadow(0px 0px 20px rgba(172, 171, 171, 0.25));
}

.programDescription {
  text-align: left;
  margin-top: 18px;
}

.thumbnail {
  width: 100%;
  min-height: 207px;
  border-radius: 8px;
  object-fit: cover;
}

.thumbnail img {
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
}

/* start program info */
.programInfoRatingGroup,
.programInfoDuration,
.programInfotrainingTypeGroup {
  display: flex;
  align-items: center;
  gap: 8px;
}

.programInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 18px;
}

.programInfo p {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.0025em;
}

.verticalLine {
  width: 1px;
  min-height: 16px;
  max-height: 100%;
  background-color: #000000;
  margin: 0 8px;
}

.programInfotrainingTypeGroup p {
  color: #25b34b;
  text-transform: capitalize;
}

.programInfoTimeStart p {
  text-transform: capitalize;
}
/* end program info */

.category {
  display: inline-block;
  padding: 8px 16px;
  background: #223a5e;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  margin-bottom: 23px;
}

.category p {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
}

.titleContainer {
  margin-bottom: 20px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.0025em;
  color: #223a5e;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .mobileContainer {
    width: 378px;
  }
}
