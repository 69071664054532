#informasi {
  .ant-card-body {
    padding: 8px;
  }
  .ant-collapse-header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 180%;
    letter-spacing: 0.0025em;
    color: #0a0a0a;
    width: 100%;
  }

  a {
    text-decoration: underline;
  }
  a:hover {
    color: #1bbc34;
  }
}
