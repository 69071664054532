/* Durasi */
.btnLihatDurasi {
  color: #223A5E;
  text-decoration: underline;
  cursor: pointer;
}

/* Profile */
.profilPengajarImgGroup {
  width: 100%;
  margin-bottom: 25px;
  padding: 20px;
  padding-left: 0;
}

.profilPengajarImgGroup img {
  width: 100%;
  border-radius: 6px;
  max-width: 400px;
}

.namePengajar {
  margin-top: 10px;
}

@media only screen and (max-width: 900px) {
  /* Profile */
}

@media only screen and (max-width: 600px) {
  /* Profile */
  .profilPengajarImgGroup {
    padding: 0;
  }
}
