#daftar-page {
  p span {
    color: #1bbc34;
  }

  a {
    color: #1bbc34;

    &:hover {
      color: #1bbc34;
    }
  }
}
