#home-page__programUnggulan {
  background: #f9fbff;
  padding-top: 104px;
  padding-bottom: 175px;
  margin-bottom: 71px;
}

@media only screen and (max-width: 600px) {
  #home-page__programUnggulan {
    padding-bottom: 50px;
    margin-bottom: 100px;
  }
}
