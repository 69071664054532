.card {
  padding: 0px 20px;
  border-radius: 10px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-bottom: 20px;
}

.pelatihan {
  display: flex;
}

.cover {
  margin-right: 20px;
  max-height: 70px;
  max-width: 99px;
  border-radius: 8px;
  object-fit: cover;
}

.category {
  font-weight: 600;
  font-size: 14px;
  color: #223a5e;
}

.name {
  color: #223a5e;
}

.hargaPelatian p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #1bbc34;
}

.rincianPembayaran {
  margin-top: 30px;
  margin-bottom: 30px;
}

.totalHarga p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #1bbc34;
}
