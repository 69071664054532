#program-detail-page__silabus-materi {
  .ant-collapse-header {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.0025em;
    color: #223a5e;
  }
  .ant-collapse-content {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #223a5e;
    ul,
    ol {
      padding-left: 16px;
    }
  }
  p {
    color: #223a5e;
  }
  .termCondition {
    color: #213a5e;
    text-decoration: underline;
  }
  .termCondition:hover {
    color: #1bbc34;
  }
}
