.silabusFasilitasGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 45px;
  margin-top: 52px;
  margin-bottom: 100px;
}

.detailProgramCardInner {
  background: #ffffff;
  padding: 18px 30px;
}

.btnLinkToProgram {
  display: flex;
  justify-content: center;
  margin-bottom: 150px;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  .silabusFasilitasGroup {
    display: grid;
    grid-template-columns: 1fr;
    gap: 45px;
    margin-top: 52px;
    margin-bottom: 100px;
  }
}
