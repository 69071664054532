#no-match-page {
  width: 100%;
  min-height: 100vh;
  background-image: url('./assets/images/background404.png');
  background-size: cover;
  background-position: center;
  display: grid;
  align-content: center;
  .container {
    max-width: 1063px;
    margin: 0 auto;
  }
  .hero-group,
  .btn-group {
    text-align: center;
  }
  .btn-group {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 1063px) {
  #no-match-page {
    .container {
      width: 90%;
      margin: 0 auto;
    }
    .hero-group img {
      width: 400px;
    }
  }
}

@media only screen and (max-width: 425px) {
  #no-match-page {
    .hero-group img {
      width: 300px;
    }
  }
}
