.container {
  padding-top: 20px;
  width: 1050px;
}
.spin {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.emptyContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px;
  width: 950px;
}
.emptyText {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  color: #223a5e;
  margin-top: 50px;
  margin-bottom: 10px;
}
.emptySubText {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #9e9e9e;
}
.modalContainer {
  display: flex;
  align-items: flex-start;
}

.modalIcon {
  padding: 5px 10px;
}

.modalTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #cb3a31;
  margin-bottom: 10px;
}

.modalText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #616161;
}
@media only screen and (max-width: 768px) {
  .emptyImage {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .emptyImage {
    width: 30%;
  }
  .emptyText {
    font-size: 26px;
  }
}
