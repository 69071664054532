@import '../../../../../assets/styles/colors';

#sidebar-menu {
  img {
    padding-right: 10px;
  }
  ul {
    border: 0px;
  }
  .ant-menu {
    background-color: #fcfdff;
    .ant-menu-item {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.005em;
      &.ant-menu-item-only-child {
        margin-left: 40px;
        width: 224px;
        height: 50px;
        a:hover {
          color: $secondaryColor;
        }
      }
      &.ant-menu-item-selected {
        background-color: #cacaca54;
        border-radius: 6px;
        // &::after {
        //   border-right: 0px;
        // }
        a {
          color: $primaryColor;
        }
        a:hover {
          color: $primaryColor;
        }
      }
    }
  }
}
