.content {
  min-height: 100vh;
  padding-top: 57px;
  padding-bottom: 70px;
  background-color: #f9fbff;
}

.titleGroup {
  margin-bottom: 51px;
}
.titleGroup h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 61px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
  margin-bottom: 19px;
}

.titleGroup p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.0025em;
}

.bayarButtonCenter {
  display: flex;
  justify-content: center;
}

.bayarButton {
  padding: 16px 65px;
  background: #1bbc34;
  border-radius: 6px;
  border: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: #ffffff;
  height: 50px;
}

.bayarButton:hover {
  background: #198d2b;
  border: 0px;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .titleGroup h2 {
    font-size: 26px;
  }
}
