.titleGroup {
  margin-bottom: 82px;
  display: flex;
  justify-content: center;
}

.titleContainer h2 {
  font-weight: 600;
  font-size: 34px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  margin-bottom: 4px;
}

.titleContainer p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.cardItem {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  padding: 42px 52px;
  cursor: pointer;
  transition: 0.2s all ease;
}

.iconContainer {
  margin-bottom: 20px;
}
.iconContainer svg {
  width: 54px;
  height: 54px;
  background: #213a5e;
  border-radius: 10px;
  padding: 7px;
}
.cardItem h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  letter-spacing: 0.0025em;
  color: #223a5e;
  margin-bottom: 10px;
}

.cardItem p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.005em;
  color: #0a0a0a;
}
.cardItem:hover {
  background-color: #213a5e;
}
.cardItem:hover svg {
  background: #ffffff;
}
.cardItem:hover svg {
  background: #ffffff;
}
.cardItem:hover svg path {
  fill: #213a5e;
}
.cardItem:hover h3 {
  color: #ffffff;
}
.cardItem:hover p {
  color: #ffffff;
}

@media only screen and (max-width: 1024px) {
  .cardContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 600px) {
  .cardContainer {
    grid-template-columns: 1fr;
  }
  .titleGroup {
    margin-bottom: 16px;
  }

  .titleContainer h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.0025em;
    margin-bottom: 8px;
  }

  .titleContainer p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0025em;
  }

  .cardItem h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.0025em;
  }

  .cardItem p {
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.0025em;
  }
}
