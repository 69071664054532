.navbarMenus {
  display: flex;
  align-items: center;
  gap: calc(45px - 15px);
}
.navbarMenus a {
  padding: 10px 15px;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #223a5e;
  transition: 0.2s all ease;
}
.navbarMenus a:hover {
  color: #1bbc34;
}
@media only screen and (max-width: 1024px) {
  .navbarMenus {
    flex-direction: column;
    gap: calc(45px - 15px);
  }
}
@media only screen and (max-width: 1440px) {
  .navbarMenus {
    gap: calc(20px - 15px);
  }
}
