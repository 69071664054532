.content {
  min-height: 100vh;
  padding-top: 57px;
  padding-bottom: 70px;
  background-color: #f9fbff;
}
.titleGroup {
  margin-bottom: 51px;
}
.title {
  display: flex;
}
.titleGroup h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 61px;
  display: flex;
  align-items: center;
  letter-spacing: 0.0025em;
}

.titleGroup p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  letter-spacing: 0.0025em;
}

.paymentMethod {
  margin-bottom: 20px;
}

.method {
  padding: 20px;
}

.titleMethod {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.0025em;
  margin-bottom: 20px;
}

.radioMethod {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.radioMethod p {
  margin-left: 10px;
}

.card {
  padding: 0px 20px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-bottom: 20px;
}

.pelatihan {
  display: flex;
}

.cover {
  margin-right: 20px;
  max-height: 70px;
  max-width: 99px;
  border-radius: 8px;
  object-fit: cover;
}

.category {
  font-weight: 600;
  font-size: 14px;
  color: #223a5e;
}

.name {
  color: #223a5e;
}

.hargaPelatian p {
  margin-top: 10px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #1bbc34;
}

.priceDetail {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.price {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #1bbc34;
  margin-right: 30px;
}

.priceBeforDiscount {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.0025em;
  color: rgba(0, 0, 0, 0.5);
  text-decoration: line-through;
}

.pricingTittle {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #223a5e;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 36px;
}

.pricingInfo {
  margin-bottom: 30px;
}

.totalHarga p {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #1bbc34;
}

.bayarButtonCenter {
  display: flex;
  justify-content: center;
}

.bayarButton {
  padding: 16px 65px;
  background: #1bbc34;
  border-radius: 6px;
  border: 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 10px;
  letter-spacing: -0.006em;
  color: #ffffff;
  height: 50px;
}

.bayarButton:hover {
  background: #198d2b;
  border: 0px;
  color: #ffffff;
}
.bayarButton:focus {
  background: #1bbc34;
  border: 0px;
  color: #ffffff;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.modalIcon {
  padding: 5px 10px;
}

.modalTitle {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #223a5e;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .titleGroup h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 900px) {
  .mobileMethod {
    display: none;
  }
}
