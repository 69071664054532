.footerContainer {
  padding: 38px 0 28px;
}

.inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 58px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #ffffff;
  margin-bottom: 30px;
}

.titleLink {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #ffffff;
}
.titleLink:hover {
  color: #ffffff;
}

.footerInput p,
.tentangKamiContent a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #ffffff;
}

/* Mindo Desc */
.logoFooter {
  min-height: 56px;
}

.logoFooter > img {
  object-fit: contain;
}

.mindoDesc {
  margin-top: 17px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #ffffff;
}

/* Social Media */
.socialMedia {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Tentang Kami */
.tentangKamiContentGroup {
  margin-top: 30px;
}

.tentangKamiContent {
  display: flex;
  gap: 16px;
  align-items: center;
}

.tentangKamiContent:not(:last-child) {
  margin-bottom: 11px;
}

.tentangKamiIcon {
  display: flex;
  justify-content: center;
  width: 25px;
}
.tentangKamiContent p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #ffffff;
}

/* Newslater */
.footerInput {
  max-width: 234px;
}

.inputEmail {
  background-color: #ffffff;
  border-radius: 3px;
  padding-right: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.btnSubmit {
  width: 29px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #213a5e;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
}

.footerInput p {
  margin-top: 12px;
}

/* Copy Right */
.copyRight {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.0025em;
  color: #ffffff;
  margin-top: 29px;
}

@media only screen and (max-width: 1024px) {
  .inner {
    gap: 18px;
  }

  /* Newslater */
  .footerInput {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 58px;
  }

  /* Newslater */
  .footerInput {
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .inner {
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 41px;
  }
}
